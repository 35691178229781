import React, { memo, useState } from "react"
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
  Annotation,
} from "react-simple-maps"
// import ReactTooltip from 'react-tooltip';
import features from "../../../map/features.json"
import asia from "../../../map/asia.json"
import oceania from "../../../map/oceania.json"

const markers = [
  {
    name: "Nigeria",
    coordinates: [8.675277, 9.081999],
    contentA: "BA/BE",
    // contentB: 'BA/BE',
    // contentC: 'Clinic Trials',
    id: "NGA",
  },
  {
    name: "Myanmar",
    coordinates: [95.956, 21.9162],
    contentA: "Contract Analysis",
    // contentB: 'BA/BE',
    // contentC: 'Clinic Trials',
    id: "MMR",
  },
  {
    name: "Philippines",
    coordinates: [121.774017, 12.879721],
    contentA: "BA/BE",
    contentB: "Contract Analysis",
    // contentC: 'Clinic Trials',
    id: "PHL",
  },
  {
    name: "Sri Lanka",
    coordinates: [80.771797, 7.873054],
    contentA: "BA/BE",
    // contentB: 'BA/BE',
    // contentC: 'Clinic Trials',
    id: "LKA",
  },
  {
    name: "United States of America",
    coordinates: [-93.26, 44.29],
    contentA: "Clinical Trials",
    // contentB: 'BA/BE',
    // contentC: 'Clinic Trials',
    id: "USA",
  },
  {
    name: "Malaysia",
    coordinates: [101.9758, 4.2105],
    contentA: "BA/BE",
    // contentB: 'BA/BE',
    // contentC: 'Clinic Trials',
    id: "MYS",
  },
  {
    name: "Vietnam",
    coordinates: [108.2772, 14.0583],
    contentA: "BA/BE",
    // contentB: 'BA/BE',
    // contentC: 'Clinic Trials',
    id: "VNM",
  },
  {
    name: "Taiwan",
    coordinates: [120.9605, 23.6978],
    contentA: "Clinical Trials",
    // contentB: 'BA/BE',
    // contentC: 'Clinic Trials',
    id: "TWN",
  },
  {
    name: "South Korea",
    coordinates: [127.7669, 35.9078],
    contentA: "Clinical Trials",
    // contentB: 'BA/BE',
    // contentC: 'Clinic Trials',
    id: "KOR",
  },
]

const MapChart = ({ setTooltipContent }) => {
  const [active, setActive] = useState(false)
  const [current, setCurrent] = useState({
    name: "",
    coordinates: [],
    contentA: "",
    contentB: "",
    contentC: "",
    id: "",
  })

  const toggleClass = (name, coordinates, contentA, contentB, contentC, id) => {
    if (active) {
      setActive(false)
      setCurrent({
        name: "",
        coordinates: [],
        contentA: "",
        contentB: "",
        contentC: "",
        id: "",
      })
    } else {
      setActive(true)
      setCurrent({
        name: name,
        coordinates: coordinates,
        contentA: contentA,
        contentB: contentB,
        contentC: contentC,
        id: id,
      })
    }
  }

  console.log(current)

  return (
    <>
      <div
        className="App"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <ReactTooltip>{content}</ReactTooltip> */}
        <div
          style={{
            width: "1910px",
            borderStyle: "double",
          }}
        >
          <div style={{ backgroundColor: "#F8F8F8" }}>
            <h2 className="pt-4 title-heading">Our International Clients</h2>
            <ComposableMap
              data-tip=""
              projectionConfig={{
                scale: 70,
                rotation: [-11, 0, 0],
              }}
              width={600}
              height={200}
              style={{ width: "100%", height: "auto" }}
            >
              <ZoomableGroup zoom={1}>
                {/* <Geographies geography='/features.json' disableOptimization> */}
                <Geographies geography={features} disableOptimization>
                  {({ geographies }) =>
                    geographies.map((geo) => (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={geo.id === current.id ? "#e4ecdc" : "#D9D9D9"}
                        // fill='#D9D9D9'
                        // onMouseEnter={() => {
                        // 	const { name } = geo.properties;
                        // 	setContent(`${name}`);
                        // }}
                        // onMouseLeave={() => {
                        // 	setContent('');
                        // }}
                        // style={{
                        // 	hover: {
                        // 		fill: '#e4ecdc',
                        // 		outline: 'none',
                        // 	},
                        // }}
                      />
                    ))
                  }
                </Geographies>
                {markers.map(
                  ({ name, coordinates, contentA, contentB, contentC, id }) => (
                    <Marker
                      key={name}
                      coordinates={coordinates}
                      onClick={() =>
                        toggleClass(
                          name,
                          coordinates,
                          contentA,
                          contentB,
                          contentC,
                          id
                        )
                      }
                    >
                      <circle r={3} fill="#00623D" strokeWidth={1} />
                    </Marker>
                  )
                )}
                {active && (
                  <Annotation
                    subject={current.coordinates}
                    dx={0}
                    dy={-50}
                    connectorProps={{
                      stroke: "#00623D",
                      strokeWidth: 0.5,
                      strokeLinecap: "round",
                    }}
                  >
                    <text
                      x="3"
                      y="0"
                      textAnchor="start"
                      alignmentBaseline="middle"
                      fill="#00623D"
                      style={{ fontSize: "7px", fontWeight: 'bold' }}
                    >
                      {current.name}
                    </text>
                    <text
                      x="3"
                      y="10"
                      textAnchor="start"
                      alignmentBaseline="middle"
                      fill="#00623D"
                      style={{ fontSize: "5px" }}
                    >
                      {current.contentA}
                    </text>
                    <text
                      x="3"
                      y="20"
                      textAnchor="start"
                      alignmentBaseline="middle"
                      fill="#00623D"
                      style={{ fontSize: "5px" }}
                    >
                      {current.contentB}
                    </text>
                    <text
                      x="3"
                      y="30"
                      textAnchor="start"
                      alignmentBaseline="middle"
                      fill="#00623D"
                      style={{ fontSize: "5px" }}
                    >
                      {current.contentC}
                    </text>
                  </Annotation>
                )}
              </ZoomableGroup>
            </ComposableMap>
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(MapChart)
